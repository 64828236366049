//SET SOCIAL LINKS IN FOOTER TO OPEN IN NEW TAB
$('.contact-list a').attr('target', '_blank');

//Menu Toggle
$('.menu-icon, .menu-close').click(function() {
	$('.site-nav').toggleClass('active');
});

$(function() {
    // Get the form.
    var form = $('#ajax-form');
    // Get the messages div.
    var formMessages = $('#form-messages');
	// Set up an event listener for the contact form.
	$(form).submit(function(event) {
	    // Stop the browser from submitting the form.
	    event.preventDefault();
	    // Serialize the form data.
	    var formData = $(form).serialize();
		// Submit the form using AJAX.
		$.ajax({
			type: 'POST',
			url: $(form).attr('action'),
			data: formData
		}).done(function(response) {
		    // Make sure that the formMessages div has the 'success' class.
		    $(formMessages).removeClass('error');
		    $(formMessages).addClass('success');
		    // Set the message text.
		    $(formMessages).text(response);
		    // Clear the form.
		    $('#first_name').val('');
		    $('#last_name').val('');
		    $('#telephone').val('');
		    $('#email').val('');
		    $('#comments').val('');
		}).fail(function(data) {
		    // Make sure that the formMessages div has the 'error' class.
		    $(formMessages).removeClass('success');
		    $(formMessages).addClass('error');
		    // Set the message text.
		    if (data.responseText !== '') {
		    	$(formMessages).text(data.responseText);
		    } else {
		    	$(formMessages).text('Oops! An error occured and your message could not be sent.');
		    }
		});
	});
});

$('.imgal-img').on('click',function(){
	let imageSrc = $(this).attr("src");
	let imageAlt = $(this).attr("alt");

	$('.imgal-container').hide();

	$('body').append(
		'<div class="imgal-modal">'+
		'<span id="imgal-modal-close"">X</span>'+
		'<img src="' + imageSrc + '" alt="' + imageAlt + '" class="imgal-modal-img"></img>'+
		'</div'
	).hide().show('fast');

	$('#imgal-modal-close').on('click',function(){
		$('.imgal-container').show();
		$('.imgal-modal').hide('fast', function(){
			$(this).remove();
		});
	});
});

